<template>
  <vs-row
    :style="{ backgroundImage: 'url(' + background + ')', height: '368px' }"
    class="rounded-lg overflow-hidden bg-no-repeat bg-center bg-cover"
    vs-type="flex"
    vs-justify="center"
    vs-align="center"
  >
    <vs-col vs-w="12" class="text-center">
      <vs-icon icon-pack="feather" icon="icon-alert-triangle" color="warning" size="50px"  />
      <h1 class="pt-2 text-quartz">Sua conta está bloqueada</h1>
      <h5 class="pt-5 text-quartz">Entre em contato com nossa equipe para o desbloqueio</h5>
      <vs-button class="mt-5" href="https://wa.me/5511976066398" target="_blank">
        <span class="flex items-center">
          Falar no WhatApp
          <WhatsappIcon color="white" width="13px" class="ml-2" />
        </span>
      </vs-button>
    </vs-col>
  </vs-row>
</template>
<script>
import background from '@/assets/images/background-block.png'
import WhatsappIcon from "@/components/svg/WhatsappIcon.vue";
export default {
  name: "Blocked",
  components: {
    WhatsappIcon
  },
  data() {
    return {
      background: background
    }
  }
}
</script>
